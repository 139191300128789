import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


    import { AddressService } from './api/address.service';
    import { AnlagenService } from './api/anlagen.service';
    import { AppConfigService } from './api/appConfig.service';
    import { AuthService } from './api/auth.service';
    import { BookingChangeTrackingService } from './api/bookingChangeTracking.service';
    import { ClientMetricsService } from './api/clientMetrics.service';
    import { CostCentreService } from './api/costCentre.service';
    import { CustomerService } from './api/customer.service';
    import { CustomerReportService } from './api/customerReport.service';
    import { DossierDocumentService } from './api/dossierDocument.service';
    import { DynamicBlockService } from './api/dynamicBlock.service';
    import { EmployeeService } from './api/employee.service';
    import { FileService } from './api/file.service';
    import { FormDefinitionService } from './api/formDefinition.service';
    import { FormInstanceService } from './api/formInstance.service';
    import { GhostWebhookService } from './api/ghostWebhook.service';
    import { HomeService } from './api/home.service';
    import { HoursService } from './api/hours.service';
    import { InfoService } from './api/info.service';
    import { InventoryService } from './api/inventory.service';
    import { MessageService } from './api/message.service';
    import { MyBusinessService } from './api/myBusiness.service';
    import { NewsService } from './api/news.service';
    import { PhoneNumberService } from './api/phoneNumber.service';
    import { ProductService } from './api/product.service';
    import { ProjectService } from './api/project.service';
    import { PublicFormsService } from './api/publicForms.service';
    import { PublicFormsDefinitionService } from './api/publicFormsDefinition.service';
    import { PurchaseOrderService } from './api/purchaseOrder.service';
    import { PushNotificationService } from './api/pushNotification.service';
    import { SalesOrderService } from './api/salesOrder.service';
    import { StorageLocationService } from './api/storageLocation.service';
    import { SupplierService } from './api/supplier.service';
    import { TimeBookingService } from './api/timeBooking.service';
    import { TimeBreakService } from './api/timeBreak.service';
    import { TimeBreakDefinitionService } from './api/timeBreakDefinition.service';
    import { TimeEntryTypeService } from './api/timeEntryType.service';
    import { TodoService } from './api/todo.service';
    import { WorkingHoursService } from './api/workingHours.service';
    import { WorkspaceService } from './api/workspace.service';
    import { ZulipService } from './api/zulip.service';

@NgModule({
imports:      [],
declarations: [],
exports:      [],
providers: [
AddressService,
AnlagenService,
AppConfigService,
AuthService,
BookingChangeTrackingService,
ClientMetricsService,
CostCentreService,
CustomerService,
CustomerReportService,
DossierDocumentService,
DynamicBlockService,
EmployeeService,
FileService,
FormDefinitionService,
FormInstanceService,
GhostWebhookService,
HomeService,
HoursService,
InfoService,
InventoryService,
MessageService,
MyBusinessService,
NewsService,
PhoneNumberService,
ProductService,
ProjectService,
PublicFormsService,
PublicFormsDefinitionService,
PurchaseOrderService,
PushNotificationService,
SalesOrderService,
StorageLocationService,
SupplierService,
TimeBookingService,
TimeBreakService,
TimeBreakDefinitionService,
TimeEntryTypeService,
TodoService,
WorkingHoursService,
WorkspaceService,
ZulipService ]
})
export class ApiModule {
public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
return {
ngModule: ApiModule,
providers: [ { provide: Configuration, useFactory: configurationFactory } ]
};
}

constructor( @Optional() @SkipSelf() parentModule: ApiModule,
@Optional() http: HttpClient) {
if (parentModule) {
throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
}
if (!http) {
throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
'See also https://github.com/angular/angular/issues/20575');
}
}
}
